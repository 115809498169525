<template>
  <label :for="name" class="fake-select-label" :class="[mode, required ? 'required' : '', selected ? 'filled' : '']">
    <span :class="selected ? 'show' : ''" class="span">{{ Label() }}</span>
    <input type="hidden" :name="String(name).toLowerCase()" class="custom-select" :value="GetValues(SelectedIds)" />
    <input :name="name + '-fake'" :placeholder="Label()"
      @change="Changed"
      @keydown.esc="HideDropdown"
      @keypress="scrollIntoView"
      @keydown.tab="HideDropdown"
      @input="OnInput"
      @focus="ShowDropdown"
      @click="ShowDropdown"
      class="custom-select-fake" :value="GetValues(SelectedLabels)" readonly />
    <div class="fake-select-dropdown">
      <div @click="SetValue(item, $event)" :data-id="index" class="fake-select-dropdown__item" :class="IsSelected(item, index)" :key="index" v-for="(item, index) in SearchedList">
        <p class="dropdown-item__text">{{ typeof item == 'object' ? item.name : item }}</p>
        <label v-if="typeof item == 'object'" :for="'option-' + item.name" class="option">
          <input :value="Object.keys(item.option)[0]" :id="'option-' + item.name" type="checkbox" :checked="IsChecked(item)" tabindex="-1">
          {{ Object.values(item.option)[0] }}
        </label>
      </div>
    </div>
  </label>
</template>

<script>
  // @keydown.delete="RemoveSelected"
  
  import { FormElementsBehaviour } from '@/mixins/FormElementsBehaviour';
  import { Tool }                  from '@/helpers/Tool';

  export default {
    name: 'Select',
    mixins: [FormElementsBehaviour, Tool],
    data() {
      return {
        query: null,
        IsBound: false,
        binding: null,
        select: {
          element: null,
          path: null,
          list: null,
          index: -1,
          key: null
        },
        SelectedIds: [],
        SelectedLabels: []
      }
    },
    props: {
      name: {
        required: true
      },
      list: {
        required: true
      },
      mode: {
        required: true
      },
      required: {
        required: false
      },
      selected: {
        required: false
      },
      readonly: {
        default: false
      }
    },
    created() {
      if (this.selected) {
        this.SelectedIds = JSON.parse(JSON.stringify(this.selected));
        this.SelectedLabels = this.GetLabels(this.SelectedIds);
      }
    },
    destroyed() {
      this.ClickHandler(false);
    },
    computed: {
      SearchedList() {
        const tmp_list = {}
        if (this.query === null) {
          return this.list;
        }
        for (const i in this.list) {
          if (this.list.hasOwnProperty(i)) {
            const item = this.list[i];
            if (typeof item == 'object') {
              if (item.name.toLowerCase().includes(this.query.toLowerCase())) {
                tmp_list[i] = item;
              }
            } else {
              if (item.toLowerCase().includes(this.query.toLowerCase())) {
                tmp_list[i] = item;
              }
            }
          }
        }
        return tmp_list;
      }
    },
    methods: {
      Label() {
        return this.$t(Tool.Capitalize(this.name));
      },
      ShowDropdown(event) {
        const select = event.target.nextSibling;
        if (this.readonly) {
          select.classList.remove('show');
          return;
        }
        if (!select.classList.contains('show')) {
          select.classList.add('show');
          this.select = select;
          if (!this.selectlist) {
            this.select.list = Array.from(select.children);
          }
          this.ClickHandler(true);
        }
      },
      HideDropdown(event) {
        if ('key' in event) {
          this.select.classList.remove('show');
          this.ClickHandler(false);
          return;
        }
        const path = event.path || (event.composedPath && event.composedPath());
        if (!path.includes(this.select) && event.target != this.select.previousSibling) {
          this.select.classList.remove('show');
          this.ClickHandler(false);
        }
      },
      SearchDropdown(event) {
        this.query = event.target.value;
      },
      scrollIntoView(event) {
        const key = event.key.toUpperCase();
        for (let target of this.select.list) {
          if (target.textContent.charAt(0) == key) {
            target.parentNode.scrollTop = target.offsetTop;
            break;
          }
        }
      },
      ClickHandler(state) {
        if (this.binding == null) {
          window.getClicks = (e) => this.HideDropdown(e);
          this.binding = window.getClicks.bind(null);
        }
        if (!state) {
          window.removeEventListener('click', this.binding);
          this.IsBound = state;
        } else {
          window.addEventListener('click', this.binding);
          this.IsBound = state;
        }
      },
      SetValue(item, event) {
        let element = event.target || {};
        let closest_div = element && element.closest('div');
        let dataset_id = closest_div && closest_div.dataset.id;
        let id = isNaN(parseInt(dataset_id)) ? dataset_id : parseInt(dataset_id);
        let label = typeof item == 'object' ? item.name : item;
        const options = JSON.parse(JSON.stringify(this.SearchedList));
        if (element.localName == 'label') return;
        if (element.localName == 'input') {
          if (element.id == 'option-' + item.name) {
            const parent_id = id;
            const parent_label = label;
            id = parseInt(element.value);
            label = item.name + ' (Admin)';
            if (!element.checked) {
              this.SelectedIds = this.SelectedIds.filter(x => x != id);
              this.SelectedLabels = this.SelectedLabels.filter(x => x != label);              
            } else {
              this.SelectedIds = this.SelectedIds.filter(x => x !== parent_id);
              this.SelectedLabels = this.SelectedLabels.filter(x => x != parent_label);
              this.SelectedIds.push(id);
              this.SelectedLabels.push(label);
            }
          }
        } else if (element.localName == 'div') {
          const option = options[id];
          const child_id = option.option && parseInt(Object.keys(option.option)[0]);
          if (this.SelectedIds.includes(child_id)) {
            const child_index = this.SelectedIds.indexOf(child_id);
            const child_label = this.SelectedLabels[child_index];
            this.SelectedIds = this.SelectedIds.filter(x => x != child_id);
            this.SelectedLabels = this.SelectedLabels.filter(x => x != child_label);
          } else if (this.SelectedIds.includes(id)) {
            this.SelectedIds = this.SelectedIds.filter(x => x != id);
            this.SelectedLabels = this.SelectedLabels.filter(x => x != label);
          } else {
            this.SelectedIds.push(id);
            this.SelectedLabels.push(label);
          }
        }
        /*
        if (!this.SelectedLabels.includes(label)) {
          this.SelectedLabels.push(label);
        }
        if (typeof item == 'object') {
          if (event.target.id == 'option-' + item.name) {
            const div_id = id;
            id = parseInt(event.target.value);
            if (event.target.checked) {
              this.SelectedIds = this.SelectedIds.filter(x => x !== div_id);
              this.SelectedIds.push(id);
            } else {
              this.SelectedIds = this.SelectedIds.filter(x => x !== id);
              this.SelectedIds.push(div_id);
            }
          } else {
            if (this.SelectedIds.includes(id)) {
              this.SelectedIds = this.SelectedIds.filter(x => x !== id);
              this.SelectedLabels = this.SelectedLabels.filter(x => x !== label);
            } else {
              const tmp_id = event.target.closest('div').querySelector('#option-' + label).value;
              if (!this.SelectedIds.includes(tmp_id)) {
                this.SelectedIds.push(id);
              } else {                            
                event.target.closest('div').querySelector('#option-' + label).checked = false;
                this.SelectedIds = this.SelectedIds.filter(x => x !== tmp_id);
                this.SelectedLabels = this.SelectedLabels.filter(x => x !== label);
              }
            }
          }
        } else {
          if (!this.SelectedIds.includes(id)) {
            this.SelectedIds.push(id);
          } else {
            this.SelectedIds = this.SelectedIds.filter(x => x !== id);
            this.SelectedLabels = this.SelectedLabels.filter(x => x !== label);
          }
        }
        */
        if (this.mode == 'single') {
          if (this.SelectedIds.length) {
            this.SelectedIds = [id];
            this.SelectedLabels = [label];
          }
          //this.query = label;
          this.ClickHandler(false);
          this.select.classList.toggle('show');
        }
        this.SelectedIds = [...new Set(this.SelectedIds)];
        this.SelectedLabels = [...new Set(this.SelectedLabels)];
        //console.log(this.SelectedIds)
        //console.log(this.SelectedLabels)
        this.$emit('update', this.SelectedIds);
        const e = new Event('change');
        event.target.closest('.fake-select-label').querySelector('.custom-select-fake').dispatchEvent(e);
      },
      IsSelected(item, index) {
        let selected = false;
        if (!isNaN(index)) {
          index = parseInt(index);
        }
        if (this.SelectedIds.includes(index)) {
          selected = true;
        } else if (typeof item == 'object') {
          const id = Object.keys(item.option)[0];
          if (this.SelectedIds.includes(parseInt(id))) {
            selected = true;
          }
        }
        if (selected) {
          return 'selected';
        }
      },
      IsChecked(item) {
        let checked = false;
        if (typeof item == 'object') {
          const id = Object.keys(item.option)[0];
          if (this.SelectedIds.includes(parseInt(id))) {
            checked = true;
          }
        }
        return checked;
      },
      GetValues(list) {
        if (list.length > 0) {
          return list.join(', ');
        }
        return this.query;
      },
      Changed(event) {
        setTimeout(() => {
          this.ValidateInput(event);
        });
      },
      OnInput(event) {
        console.log(event, event.type)
        if (this.mode == 'single') {
          this.SearchDropdown(event);
          this.ValidateInput(event);
          this.ShowDropdown(event);
        }
      },
      RemoveSelected(event) {
        this.SelectedIds = [];
        this.SelectedLabels = [];
        this.query = event.target.value;
      },
      GetLabels(ids) {
        let labels = [];
        ids.map(id => {
          let el;
          if (this.list[id]) {
            if (typeof this.list[id] == 'object') {
              el = this.list[id].name;
            } else {
              el = this.list[id];
            }
          } else {
            for (let item in this.list) {
              let element = this.list[item];
              if (typeof element == 'object') {
                if (element.option[id]) {
                  el = element.name + ' (Admin)';
                }
              }
            }
          }
          labels.push(el);
        });
        return labels;
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
